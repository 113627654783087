import './style.scss';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Service from './components/Service';
import Brands from './components/Brands';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ChooseUs from './components/ChooseUs';

function App() {
  return (
    
    <div className="App">
      
      <NavBar/>
      <Home />
      <ChooseUs/>
      <Service />
      <Contact />
      <Brands />
      <Footer />
    </div>
  );
}

export default App;
