import React, { useEffect, useState } from "react";
import { Link } from 'react-scroll';
import { AiOutlineArrowRight } from 'react-icons/ai'
import Video from '../assets/bgvideo.mp4'
import logo from '../assets/logo.png'


const Home = () => {
  const [isScrolled, setIsScrolled] = useState(false);

   useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className='home' id='home'>
      <div className='home-container'>
        <div className='video-bg'>
          <video src={Video} autoPlay loop muted></video>
        </div>

        <div className='section-text'>
          <h2>
          Welcome to
          </h2>
          <div className="logo"><img src={logo} alt="safeteclk" /></div>
          <h1>Safes, Lockers Repair and Service</h1>

          <p>Your Trusted Partner in Locker Repairs and Maintenance. </p>

          <Link to="contact" smooth={true} duration={500}  className='btn flex'> Contact Now<AiOutlineArrowRight className='icon'/></Link>
        </div>
      </div>
    </div>

             
    )
  }

export default Home