import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { PiDotsNineBold } from "react-icons/pi";
import {ImFacebook} from 'react-icons/im';
import { RiWhatsappFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Link } from 'react-scroll';
import logo from '../assets/ST.png';
import { NavLink} from "react-router-dom";
 

const NavBar = () => {
  const [navBar , setNavBar] = useState('menu');
  const [isScrolled, setIsScrolled] = useState(false);

  const showNavBar = () => {
    setNavBar('menu showNavbar');
  }

  const removeNavBar = () => {
    setNavBar('menu');
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="header" id="home">
      <div className= "contact-detail-bar">
        <ul>
          <div className="hotline"><BsFillTelephoneFill className="icon"/> &nbsp; <span>Hotline : 011 2713 858</span></div>
        </ul>
        <ul>
          <NavLink to="https://www.facebook.com/profile.php?id=61555260806120&mibextid=hIlR13"><li className="social-media"><ImFacebook className="icon"/></li></NavLink>
          <NavLink to="https://wa.me/+94706615795"><li className="social-media"><RiWhatsappFill className="icon"/></li></NavLink>
          <NavLink to="https://www.linkedin.com/in/safetec-enterprices-2357662a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><li className="social-media"><FaLinkedin className="icon"/></li></NavLink>
        </ul>
      </div>

      <div className={`navBar ${isScrolled ? 'scrolled' : 'notScrolled'}`}> 
        <div className="logoDiv">
          <Link to="home" smooth={true} duration={500}><img src={logo} alt="Safetec" /></Link>
        </div>

        <div className={navBar}>
          <ul>
            <Link to="home" smooth={true} duration={500}><li className="navList">Home</li></Link>
            <Link to="why-choose-us" smooth={true} duration={500}><li className="navList">Why Choose Us</li></Link>
            <Link to="service" smooth={true} duration={500}><li className="navList">Service</li></Link>
            <Link to="contact" smooth={true} duration={500}><li className="navList">Contact</li></Link>
            <Link to="brands" smooth={true} duration={500}><li className="navList">Brands</li></Link>
          </ul>

          <AiFillCloseCircle className="icon closeIcon" onClick={removeNavBar}/>

        </div>
        <PiDotsNineBold className='icon menuIcon' onClick={showNavBar}/>
      </div>
    </div>
  );
};

export default NavBar;


