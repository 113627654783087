import React from "react";
import {ImFacebook} from 'react-icons/im'
import { RiWhatsappFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { NavLink} from "react-router-dom";

 import logo from '../assets/logo.png';

const Footer = () => {

  const currentYear = new Date().getFullYear();

   return (

    <div className="footer" id="footer">

      <div className="footer-container">
        <div className="logo-div item">
          <img src={logo} alt="Safetec" />
        </div>

        <div className="footerLinks socials item">
          <NavLink to="https://www.facebook.com/profile.php?id=61555260806120&mibextid=hIlR13"><li className="social-media"><ImFacebook className="icon"/></li></NavLink>
          <NavLink to="https://wa.me/+94706615795"><li className="social-media"><RiWhatsappFill className="icon"/></li></NavLink>
          <NavLink to="https://www.linkedin.com/in/safetec-enterprices-2357662a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><li className="social-media"><FaLinkedin className="icon"/></li></NavLink>
        </div>

        <div className="footerLinks contact-details item">
            <p className="phone">
              011 271 3858<br/>
              070 661 5795<br/>
              077 321 5795
            </p>
            <p className="email">
              safetec115@gmail.com
            </p>
        </div>
        <div className="footerLinks address item">
          <p className="address">
            Safetec enterprises<br/>
            16/A/8,
            Berawawala, <br/>
            Madapatha,
            Piliyanadala.
          </p>
        </div>

        <div className="footerLinks credits item">
          <li>
            <a href="https://www.freepik.com/free-photo/plumber-with-his-arms-crossed_1142835.htm#query=technician&position=7&from_view=search&track=sph&uuid=90d6f694-4ae5-4963-a0a8-fe2ef8c3e59b">Image by luis_molinero</a>
          </li>
          <li>
            <a href="https://www.freepik.com/free-photo/busy-woman-doing-many-things-same-time_865252.htm#query=contact%20us&position=2&from_view=search&track=ais&uuid=09f0df1e-83fa-41cf-816b-a37b5a924ce2">Image by pressfoto</a>
          </li>
        </div>
      </div>
      <div className="footer-line1">
      <p>
      Copyright &copy; {currentYear} Safetec All Rights Reserved
      </p>
      </div>
      <div className="footer-line2">
        <p>Design & Developed by Lumina</p>
      </div>
    </div>
  );
}

export default Footer;