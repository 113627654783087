import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Service1 from '../assets/services/service 1.jpg';
import Service2 from '../assets/services/service 2.jpeg';
import Service3 from '../assets/services/service 3.jpeg';
import Service4 from '../assets/services/service 4.jpeg';
import Service5 from '../assets/services/service 5.jpeg';
import Service6 from '../assets/services/service 6.jpg';
import Service7 from '../assets/services/service 7.jpeg';
import FreeService from '../assets/services/free service.png';

const Service = () => {
  return (
      <div className="service" id='service'>
        <div className="service-container">
          <h2>Our Service</h2>
          <Container align='center' justify = 'center'>
            <Row align='center' justify = 'center' className='row-container'>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className="content">
                <div className='services'>
                  <h3>All kind of safes repair and service.</h3>
                  <img src={Service1} alt="Safe repair" />
                  <p>"Elevate your security with our expert safe repair and service. Whether it's combination locks or electronic systems, trust us for precision and peace of mind. Your valuables, our priority!"</p>
                </div>
              </Col>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className='content'>
                <div className='services'>
                  <h3>Time lock service and repair.</h3>
                  <img src={Service2} alt="Time Lock service" />
                  <p>"Time lock service and repair for precision and reliability. Trust us to keep your security ticking seamlessly."</p>
                </div>
              </Col>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className='content'>
                <div className='services'>
                  <h3>Vault door repair, removing, installation & service.</h3>
                  <img src={Service3} alt="Vault door repair" />
                  <p>"Elevate your security with our comprehensive vault door services. From efficient repairs and seamless removals to expert installations and ongoing maintenance, we are your trusted partner for top-notch vault door service."</p>
                </div>
              </Col>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className='content'>
                <div className='services'>
                  <h3>Fire exit door installation.</h3>
                  <img src={Service4} alt="Fire exit door installation" />
                  <p>"Secure your safety with expert fire exit door installations. Our professional team ensures swift and reliable service for enhanced emergency preparedness."</p>
                </div>
              </Col>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className='content'>
                <div className='services'>
                  <h3>All kind of compactor installation, removing, fixing & repair.</h3>
                  <img src={Service5} alt="Compactor Repair" />
                  <p>"Comprehensive compactor services for installation, removal, fixing, and repair. Trust us for efficient solutions across all types of compactors."</p>
                </div>
              </Col>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className='content'>
                <div className='services'>
                  <h3>Repainting safe, vault door, special door and other products.</h3>
                  <img src={Service6} alt="Repainting" />
                  <p>"Revitalize your security with our professional repainting services. From safes and vault doors to special doors and more, we enhance both aesthetics and protection."</p>
                </div>
              </Col>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className='content'>
                <div className='services'>
                  <h3>All types of chair refabric & repair.</h3>
                  <img src={Service7} alt="Chair Refabric & Repair" />
                  <p>"Renew your chairs with our expert refabrication and repair services. We cater to all types, ensuring a fresh look and enhanced durability for your seating."</p>
                </div>
              </Col>

              <Col xl={3} md={3.5} sm={5} xs={7} align='center' justify = 'center' className='content'>
                <div className='services free-service'>
                  <h3>Free Service</h3>
                  <img src={FreeService} alt="Chair Refabric & Repair" />
                  <p>* One free service is provided after repair</p></div>
              </Col>

            </Row>
          </Container>
        </div>
      </div>
    );
  };
export default Service

