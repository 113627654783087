import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Brnad1 from '../assets/brand1.png';
import Brnad2 from '../assets/brand2.png';
import Brnad3 from '../assets/brand3.png';
import Brnad4 from '../assets/brand4.png';
import Brnad6 from '../assets/brand6.png';
import Brnad7 from '../assets/brand7.png';
import Brnad8 from '../assets/brand8.png';
import Brnad9 from '../assets/brand9.jpg';



function Brands() {

  return (
    <div className='brands' id='brands'>
      <h2>Brands we are working with</h2>
      <div>
        <Swiper
          className='swiper'
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          breakpoints={{
            320: { slidesPerView: 1 },
            410: { slidesPerView: 2 },
            734: { slidesPerView: 3 },
            1100: { slidesPerView: 4 },
            1400: { slidesPerView: 5 }
          }}
          navigation
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide><div className='container'><img src={Brnad1}alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='container'><img src={Brnad2}alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='container'><img src={Brnad3}alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='container'><img src={Brnad4}alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='container'><img src={Brnad6}alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='container'><img src={Brnad7}alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='container'><img src={Brnad8}alt="" /></div></SwiperSlide>
          <SwiperSlide><div className='container'><img src={Brnad9}alt="" /></div></SwiperSlide>

        </Swiper>
      </div>
    </div>
  )
}

export default Brands
