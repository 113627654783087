import {ImFacebook} from 'react-icons/im';
import { RiWhatsappFill } from "react-icons/ri";
import { FaLinkedin } from "react-icons/fa";
import { Link} from "react-router-dom";
import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      setShowPopup(true);
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_k0kxly3', 'template_wkkr8jm', form.current, 'gnRIUhOflKfNNb9oO')
      .then((result) => {
          console.log(result.text);
          console.log("Message Sent");
          e.target.reset();
          setIsSubmitted(true);
      }, (error) => {
          console.log(error.text);
          console.log("Try again later")
      });
  };

  return (
    <div className='contact' id='contact'>
      <div className='contact-container'>
        <div className="background-image" />
          <div className="content">
              <div className='social-media-content'>
                <h2>Contact via Social Media</h2>
                {/* <ul>
                <li className="social-media"><Link to="https://www.facebook.com/profile.php?id=61555260806120&mibextid=hIlR13"><ImFacebook className="icon"/></Link></li>
                <li className="social-media"><Link to="https://wa.me/+94706615795"><RiWhatsappFill className="icon"/></Link></li>
                <li className="social-media"><Link to="https://www.linkedin.com/in/safetec-enterprices-2357662a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedin className="icon"/></Link></li>
                </ul> */}
                <ul>
                  <li><a href="https://www.facebook.com/profile.php?id=61555260806120&mibextid=hIlR13"><ImFacebook className="icon"/></a></li>
                  <li><a href="https://wa.me/+94706615795"><RiWhatsappFill className="icon"/></a></li>
                  <li><a href="https://www.linkedin.com/in/safetec-enterprices-2357662a7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedin className="icon"/></a></li>
                </ul>

              </div>
        <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-group">
                <label htmlFor="name">name</label>
                <input type="text" name="name" id="name" placeholder="Your Name"/>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" placeholder="Your Email" />
              </div>
              <div className="form-group">
                <label htmlFor="contactNumber">Contact Number</label>
                <input type="text" name="contact_number" id="contact_number" placeholder="Your Contact Number" />
              </div>
              <div className="form-group">
                <label htmlFor="service">Service</label>
                <input type="text" name="service" id="service" placeholder="Type of Service" />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" placeholder="Your Message" />
              </div>
              <button type="submit" className='btn' id="button" value="Send Email">Submit</button>
            </form>
            {showPopup && (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '10px', background: 'green', color: 'white', borderRadius: '5px' }}>
          Successfully Sent <span>&#10004;</span>
        </div>
      )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact

