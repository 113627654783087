import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import Image from '../assets/WhyChooseUs.png';
import CompetitiveRates from '../assets/cost-effective.png';
import Warrenty from '../assets/warrenty.png';
import Fast from '../assets/fast.png';
import Qaulity from '../assets/quality-service.png';
import Protective from '../assets/protecting.png';
import Prevent from '../assets/prevent.png';
import Hour24 from '../assets/24 hours.png';
import ComeToYou from '../assets/come to you.png';
import Workshop from '../assets/workshop.png';
import Friendly from '../assets/friendly.png';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';



const ChooseUs = () => {

    return (
      
      <div id="why-choose-us">
        <ParallaxProvider>
        <div className='choose-us-container'>
          <h2>Why Choose Us?</h2>
          <p>
          The Safetec service team comprises a group of well - trained and highly competent individuals - dedicated to always providing great service. Fast, efficient and reliable - their wark is of unmatched quality. Call our service center or open a new job and let our expert technicians take care of it!
          </p>
          <p>We will visit the relevant location for repairs, installation, servicing and maintenance.</p>

          <div className='section-update'>

          <h3>Expertise and experience</h3>
          <p>
            With over 27 yeare of experience in the industry, Safetec services has a skilled team of professionals who possess deep expertise in their respective fields.
          </p>

          <h3>Efficient solutions</h3>

          <p>Service process, we strive to deliver exceptional customer support.</p>

          <h3>Quality service guarantee</h3>

          <p>At Safetec services, we priorities customer satisfaction and stand behind the quality of our work.</p>

          </div>


        <Container>
          <Row>
            <Col justify="center" align='center' className="left-col">
            <Col>
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={CompetitiveRates} alt="" />
                  <p>Providing the service at fair and competitive rates.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col>
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={Fast} alt=""/>
                  <p>Efficient fast service.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col >
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={Protective} alt="" />
                  <p>Protecting corporate confidential matters.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col >
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={Hour24} alt="" />
                  <p>24 hour service islandwide.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col >
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={Workshop} alt="" />
                  <p>Conducting free workshops on the safe protecting.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            </Col>

            <Col justify="center" className='middle-col'>
                  <img src={Image} alt="Expertise Icon" />    
            </Col>

            <Col justify="center" className='right-col'>
            <Col>
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
              <div className="category-box">
                  <img src={Warrenty} alt="" />
                  <p>Providing a one-year warranty for all the services.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col>
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={Qaulity} alt="" />
                  <p>Use of quality spare parts.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col>
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={Prevent} alt="" />
                  <p>Prevent the theft by third parties.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col>
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={ComeToYou} alt="" />
                  <p>We will come to you and provide our services.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            <Col>
            <Parallax scale={[0, 1, 'easeInOut']} easing={[1, -2.9, 1, 2]}>
              <div className="feature-category">
                <div className="category-box">
                  <img src={Friendly} alt="" />
                  <p>Friendly technician service.</p>
                </div>
              </div>
              </Parallax>
            </Col>
            </Col>

          </Row>

        </Container>
        </div>
        </ParallaxProvider>
      </div>
    );
  };
export default ChooseUs;